import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from "../components/logo"

const StyledHeader = styled.header`
  grid-area: header;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  color: white;
  background: black;
  padding: 1.45rem 1.0875rem;
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 10px;
  :hover {
    color: red;
  }
`

const Header = () => (
  <StyledHeader>
    <div style={{ minWidth: "300px", width: "25%" }}>
      <Link to="/">
        <Logo />
      </Link>
    </div>
    <StyledNav>
      <StyledLink to="/">HOME</StyledLink>
      <StyledLink to="/brands">BRANDS</StyledLink>
      <StyledLink to="/contact">CONTACT</StyledLink>
    </StyledNav>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
