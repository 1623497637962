import React from "react"
import {
  FaLinkedin,
  FaTwitter,
  FaEnvelopeSquare,
  FaPhoneSquare,
} from "react-icons/fa"
import MapStatic2 from "../components/map-static-on-build"
import styled from "styled-components"

const StyledFooter = styled.footer`
  grid-area: footer;
  color: white;
  background: black;
`
const StyledContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 1.0875rem 1rem;
`

const StyledSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
`

const StyledA = styled.a`
  text-decoration: none;
  color: white;
  padding-right: 1em;
  padding-left: 0.5em;
  :hover {
    color: red;
  }
`

const StyledSpan = styled.span`
  color: red;
  font-weight: bold;
`

const address = (
  <p>
    <StyledSpan>Physical Address</StyledSpan>
    <br />
    Bada Bing LLC
    <br />
    30 N Gould STE 4000
    <br />
    Sheridan, WY 82801 <br />
    USA
  </p>
)

const phoneNo = (
  <div>
    <FaPhoneSquare color="red" size={20} />
    <StyledA href="tel:3072284664" style={{ verticalAlign: "15%" }}>
      307-228-4664
    </StyledA>
  </div>
)

const email = (
  <div>
    <FaEnvelopeSquare color="red" size={20} />
    <StyledA
      href="mailto:info@badabing.co?Subject=Website%20Contact"
      target="_top"
      style={{ verticalAlign: "15%" }}
    >
      info@badabing.co
    </StyledA>
  </div>
)

const copywrite = (
  <span>
    © {new Date().getFullYear()}
    <StyledA
      target="_blank"
      rel="noopener noreferrer"
      href="https://bada-bing-llc.business.site/"
    >
      Bada Bing LLC
    </StyledA>
  </span>
)

const twitter = (
  <StyledA
    target="_blank"
    rel="noopener noreferrer"
    href=" https://twitter.com/badabingllc"
    aria-label="twitter"
  >
    <FaTwitter size={30} />
  </StyledA>
)

const li = (
  <StyledA
    target="_blank"
    rel="noopener noreferrer"
    href="https://linkedin.com/company/bada-bing-llc/"
    aria-label="linkedin"
  >
    <FaLinkedin size={30} />
  </StyledA>
)

const Footer = () => (
  <>
    <StyledFooter>
      <StyledContainer>
        <StyledSection>
          <div style={{ width: "150px", flexGrow: "1" }}>{address}</div>
          <div style={{ width: "150px", flexGrow: "1", paddingBottom: "1em" }}>
            {phoneNo}
            {email}
          </div>
          <div style={{ width: "400px", flexGrow: "2" }}>
            <MapStatic2 />
          </div>
        </StyledSection>
        <div style={{ paddingTop: "1rem" }}>
          {twitter} {li}
        </div>
        <div>{copywrite} </div>
      </StyledContainer>
    </StyledFooter>
  </>
)

export default Footer
